@import "./fonts";
@import "./customs";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "NunitoSans-Regular", sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}

.object-cover {
  object-fit: cover;
}

h1 {
  font-size: 72px;
  @media (max-width: 768px) {
    font-size: 36px;
  }
}

h2 {
  font-size: 60px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 576px) {
    font-size: 24px;
  }
}

h3 {
  font-size: 48px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
}

h4 {
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 576px) {
    font-size: 16px;
  }
}
