$primary: #ef4136;
$white: #ffffff;
$black: #000000;

$theme-colors: (
  "primary": $primary,
);

// button

.btn.btn-primary {
  color: $white !important;
  &:hover {
    background-color: $black;
    border-color: $black;
  }
}

// link
.nav-link {
  color: $white;
}

@import "/node_modules/bootstrap/scss/bootstrap.scss";
