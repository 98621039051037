/*Fonts*/

// @font-face {
// 	font-family: 'NunitoSans-Black';
// 	src: url(../fonts/NunitoSans-Black.ttf);
// }

// @font-face {
// 	font-family: 'NunitoSans-BlackItalic';
// 	src: url(../fonts/NunitoSans-BlackItalic.ttf);
// }

// @font-face {
// 	font-family: 'NunitoSans-Bold';
// 	src: url(../fonts/NunitoSans-Bold.ttf);
// }

// @font-face {
// 	font-family: 'NunitoSans-BoldItalic';
// 	src: url(../fonts/NunitoSans-BoldItalic.ttf);
// }

@font-face {
  font-family: "NunitoSans-ExtraBold";
  src: url(../fonts/NunitoSans-ExtraBold.ttf);
}

// @font-face {
// 	font-family: 'NunitoSans-ExtraBoldItalic';
// 	src: url(../fonts/NunitoSans-ExtraBoldItalic.ttf);
// }

@font-face {
  font-family: "NunitoSans-Regular";
  src: url(../fonts/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: "NunitoSans-SemiBold";
  src: url(../fonts/NunitoSans-SemiBold.ttf);
}
